import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { CircularProgress, Grid } from "@mui/material";
import { getFile } from "app/productRegistration/service/prodctRegistration";
import { useDispatch } from "react-redux";
import MDButton from "components/MDButton";
import { downloadurl } from "Services/APIs";

const ViewDelar = (props) => {
  const dispatch = useDispatch();
  const { title, selectedProduct, openpopup, setopenpopup } = props;
  console.log(selectedProduct);
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")).profileid
  );
  const [isloading, setLoading] = useState(false);
  const [filedata, setFiledata] = useState({});
  console.log(selectedProduct);
  const handleClose = () => {
    setopenpopup(false);
  };
  const getfiledata = {
    profileId: profileId,
    // code: selectedProduct?.code,
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loader
      if (selectedProduct !== null) {
        try {
          const response = await dispatch(getFile(getfiledata));

          setFiledata(response.payload[0]);
          setLoading(false); // Show loader
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false); // Show loader
        }
      }
    };

    fetchData();
  }, [selectedProduct]);

  

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#D91029",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent >
        <div style={{ textAlign: "center" }}>
          <img
            src="https://aliceblueonline.com/wp-content/uploads/2022/10/Form-Caricature.png"
            alt="photo"
            style={{ width: "80%", height: "300px" ,marginTop:'10px' }}
          />
        </div>

        {isloading ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <CircularProgress />
          </div>
        ) : (
          <MDBox mt={1.625}>
            <Grid container spacing={3} ml={2}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Name:{" "}
                </span>{" "}
                {selectedProduct?.name}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  firmname:{" "}
                </span>{" "}
                {selectedProduct?.
firmname}
              </Grid>
            </Grid>
         
            <Grid container spacing={3} ml={2} mt={0.1}>
             
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Mobile Number:{" "}
                </span>{" "}
                {selectedProduct?.mobileno}
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Email:{" "}
                </span>{" "}
                {selectedProduct?.emailid}
              </Grid>
            </Grid>
            <Grid container spacing={3} ml={2} mt={0.1}>
            
              <Grid item xs={12} sm={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={() => {
                    // window.open(filedata?.downloadurl);
                    window.open(`${downloadurl}/${selectedProduct?.documentofproof}`);
                  }}
                >
                  view invoice
                </MDButton>
                <button fontSize="medium" />
              </Grid>
            </Grid>
            <Grid container spacing={3} ml={2} mt={0.1}>
              {/* <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  File Name:{" "}
                </span>{" "}
                {filedata?.filename}
              </Grid> */}
             
            </Grid>
           
          </MDBox>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};



export default ViewDelar;
