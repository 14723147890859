// import React, { useState, useEffect, useRef } from "react";
// import { useForm } from "react-cool-form";
// import MDInput from "components/MDInput";
// import { useNavigate } from "react-router-dom";
// import Card from "@mui/material/Card";
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// import CoverLayout from "layouts/authentication/components/CoverLayout";
// import bgImage from "assets/images/bg-pricing.jpg";
// import MDTypography from "components/MDTypography";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import { logIn } from "../../../../redux/Actions/authActions";
// import { useDispatch, useSelector } from "react-redux";
// import lottie from "lottie-web";
// import { Link } from "react-router-dom";
// import Grid from "@mui/material/Grid";
// import { MoonLoader } from "react-spinners";
// export default function Basic() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const container = useRef(null);
//   const { isAuthenticated } = useSelector(({ AuthReducer }) => AuthReducer);

//   const { form, use } = useForm({
//     defaultValues: { email: "", password: "", resourcecode: "JAINA" },
//     onSubmit: (values) => loginUser(values),
//   });

//   const errors = use("errors");
//   const [isLoading, setIsLoading] = useState(false); // Add loading state

//   const loginUser = async (data) => {
//     setIsLoading(true); // Set loading state to true
//     const getData = {
//       username: data.email,
//       password: data.password,
//       resourcecode: "JAINA",
//     };

//     dispatch(logIn(getData)).then(() => {
//       setIsLoading(false); // Set loading state back to false when authentication is finished
//     });
//   };

//   useEffect(() => {
//     if (isAuthenticated) {

//       navigate("/pages/profile/profile-overview");
//       window.location.reload();

//     }
//   }, [isAuthenticated]);

//   useEffect(() => {
//     lottie.loadAnimation({
//       container: container.current,
//       renderer: "svg",
//       autoplay: true,
//       animationData: require("./gear.json"),
//     });
//   }, []);

//   return (
//     <CoverLayout image={bgImage}>
//       <Card>
//         <MDBox
//           variant="gradient"
//           bgColor="info"
//           borderRadius="lg"
//           coloredShadow="success"
//           mx={2}
//           mt={-3}
//           p={3}
//           mb={1}
//           textAlign="center"
//         >
//           <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
//             Sign in
//           </MDTypography>
//           <MDTypography display="block" variant="button" color="white" my={1}>
//             Enter your mpin and password to Sign In
//           </MDTypography>
//         </MDBox>

//         <form ref={form} noValidate>
//           <MDBox pt={4} pb={3} px={3}>
//             <MDBox mb={2}>
//               <MDInput name="email" placeholder="Enter Mobile" required fullWidth />
//               {errors.email && (
//                 <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>
//                   {errors.email}
//                 </p>
//               )}
//             </MDBox>

//             <MDBox mb={2}>
//               <MDInput name="password" type="password" placeholder="Enter Mpin" required fullWidth />
//               {errors.password && (
//                 <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>
//                   {errors.password}
//                 </p>
//               )}
//             </MDBox>
//             <MDBox mb={2} mt={5}>

//               <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={isLoading}>
//                           {isLoading ? (
//                             <MoonLoader color="#f2fefa" size={16} />
//                           ) : (
//                             "Log In"
//                           )}
//                         </MDButton>
//             </MDBox>
//             <MDTypography variant="button" color="text" style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
//               <MDTypography
//                 component={Link}
//                 to="/app/ForgotPassword/forgot-password"
//                 variant="button"
//                 color="info"
//                 fontWeight="medium"
//                 textGradient
//               >
//                 ForgotPassword?
//               </MDTypography>
//             </MDTypography>
//           </MDBox>
//         </form>

//         <MDBox mt={2} mb={1} textAlign="center">
//           <MDTypography variant="button" color="text">
//             Don&apos;t have an account?{" "}
//             <MDTypography
//               component={Link}
//               to="/app/newregistration/registration"
//               variant="button"
//               color="info"
//               fontWeight="medium"
//               textGradient
//             >
//               Sign up
//             </MDTypography>
//           </MDTypography>
//         </MDBox>
//       </Card>
//     </CoverLayout>
//   );
// }

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { MoonLoader } from "react-spinners";
import bgImage from "assets/images/bg-pricing.jpg";
import axios from "../../../../axiosinstance";

import { useNavigate } from "react-router-dom";
import {
  conformforgotOtp,
  forgotPassword,
} from "app/Forgot Password/service/Forgotpassword";
import { logIn } from "redux/Actions/authActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const initialValues = {
  mobileNumber: "",
  otp: "",
};

const validationSchema = Yup.object({
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
  otp: Yup.string().when(["mobileNumber", "showOTPInput"], {
    is: (mobileNumber, showOTPInput) => showOTPInput && !!mobileNumber,
    then: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP must be 6 digits"),
  }),
});

export default function Basic() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(({ AuthReducer }) => AuthReducer);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/pages/profile/profile-overview");
      // window.location.reload();
    }
  }, [isAuthenticated]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      if (!showOTPInput && values.mobileNumber.length === 10) {
        try {
          const response = await axios.get(
            `login-service-mongo/api/loginservice/v2/get?mobileno=${values.mobileNumber}`
          );
          if (response?.data?.data?.listSize === 0) {
            navigate("/app/newregistration/registration", {
              state: { mobileno: values.mobileNumber },
            });
            return;
          }
          console.log("Login response: ", response?.data?.data);
        } catch (error) {
          swal(
            "Oops!",
            error?.response?.data?.aceErrors?.[0]?.errorMessage,
            "warning"
          );
          return;
        }

        const otpData = {
          otptype: "SMS",
          typevalue: "DIRECT",
          resourcename: "Jaina India",
          resourcecode: "JAINA",
          status: "ACTIVE",
          userid: values.mobileNumber,
          classname:"Otps"
        };

        await axios
          .post(`otps-service/api/Otps/v2/create`, otpData)
          .then((response) => {
            let otp = response?.data?.data?.dto?.otp;
            // console.log("OTP generated successfully: ", otp);
            // alert(`Generated OTP is: ${otp}`);
            setShowOTPInput(true);
          })
          .catch((error) => {
            swal(
              "Oops!",
              error?.response?.data?.aceErrors?.[0]?.errorMessage,
              "warning"
            );
          })
          .finally(() => {
            setIsLoading(false);
          });

        // try {
        //   const responseData = await forgotPassword(values.mobileNumber);
        //   console.log("API Response:", responseData);
        //   setShowOTPInput(true);
        //   setIsLoading(false);
        // } catch (error) {
        //   swal(
        //     "Oops!",
        //     error.response.data.aceErrors[0].errorMessage,
        //     "warning"
        //   );
        //   setIsLoading(false);
        // }
      } else if (showOTPInput) {
        setIsLoading(true);
        const data = {
          otp: values.otp,
          mobileNumber: values.mobileNumber,
        };

        dispatch(logIn(data)).then(() => {
          setIsLoading(false);
        });

        // await axios
        //   .post(
        //     `otps-service/api/Otps/v2/confirmV2?otp=${values.otp}&userid=${values.mobileNumber}&msgType=DIRECT`
        //   )
        //   .then((response) => {
        //     console.log("API Response:", response);
        //     const data = response?.data?.data?.dto;
        //     const getData = {
        //       username: data.email,
        //       password: data.password,
        //       resourcecode: "JAINA",
        //     };
        //   })
        //   .catch((error) => {
        //     // swal(
        //     //   "Oops!",
        //     //   error?.response?.data?.aceErrors?.[0]?.errorMessage,
        //     //   "warning"
        //     // );
        //     navigate("/app/newregistration/registration");
        //   })
        //   .finally(() => {
        //     setIsLoading(false);
        //   });

        // try {
        //   const responseData = await conformforgotOtp(
        //     values.mobileNumber,
        //     values.otp
        //   );
        //   console.log("API Response:", responseData);
        //   navigate("/app/NewMpin/new-mpin", { state: { responseData } });
        //   setShowOTPInput(true);
        //   setIsLoading(false);
        // } catch (error) {
        //   swal(
        //     "Oops!",
        //     error.response.data.aceErrors[0].errorMessage,
        //     "warning"
        //   );
        //   setIsLoading(false);
        // }
      }
      setSubmitting(false);
    },
  });

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-1}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {showOTPInput ? "Enter OTP" : "Enter your Mobile Number"}
          </MDTypography>
        </MDBox>

        <form onSubmit={handleSubmit}>
          <MDBox pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <MDInput
                  fullWidth
                  name="mobileNumber"
                  id="mobileNumber"
                  type="text"
                  label="Mobile Number"
                  autoComplete="off"
                  value={values.mobileNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.mobileNumber && touched.mobileNumber && (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.mobileNumber}
                  </p>
                )}
              </Grid>
              {showOTPInput && (
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    name="otp"
                    id="otp"
                    type="text"
                    label="OTP"
                    autoComplete="off"
                    value={values.otp}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.otp && touched.otp && (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {errors.otp}
                    </p>
                  )}
                </Grid>
              )}
              <Grid container spacing={3} mt={1} mb={4}>
                <Grid item xs={12} sm={8}></Grid>
                <Grid item xs={12} sm={4}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    type="submit"
                    disabled={isLoading}
                    style={{ position: "absolute", right: "8%" }}
                  >
                    {isLoading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : showOTPInput ? (
                      "Submit OTP"
                    ) : (
                      "Submit"
                    )}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </form>
        {/* <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Already have an account?{" "}
            <MDTypography
              component={Link}
              to="/app/newregistration/registration"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign Up
            </MDTypography>
          </MDTypography>
        </MDBox> */}
      </Card>
    </CoverLayout>
  );
}
