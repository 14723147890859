import * as React from "react";
 
// @mui material components
import Grid from "@mui/material/Grid";
 
// @mui material components
import Card from "@mui/material/Card";
 
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
 
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
 
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";
 
import axios from "../../axiosinstance";
import { BarLoader } from "react-spinners";
import { Autocomplete, FormControlLabel, Switch } from "@mui/material";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";




import MDButton from "components/MDButton";
import ViewDelar from "./ViewDelar";
import PreviewIcon from "@mui/icons-material/Preview";


 
const DelarMaster = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [isactive, setIsactive] = useState("");
  const [code, setCode] = useState(null);
  const [color] = useState("#344767");
  const [openpopup, setopenpopup] = useState(false);
   const [selectedProduct, setSelectedProduct] = useState(null);
 
  const [openpopupPassword, setopenpopupPassword] = useState(false);
  const [passdata, setPassdata] = useState({});
 

  const dataObj = {
    columns: [
      { Header: "Name", accessor: "name" },
     
      { Header: "Firm Name", accessor: "firmname" },
      {
        Header: "mobile no",
        accessor: "mobileno",
      },
      { Header: "Email", accessor: "emailid" },
      { Header: "Status", accessor: "recstatus" },
      { Header: "change Status", accessor: "switch" },
     { Header: "View", accessor: "view" },
 
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const iconStyle = {
    fontSize: "1.5em",
    color: "#344767",
    // marginRight: "9px",
    color: "red",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const viewproductDetail = (item) => {
    setopenpopup(true);
    setSelectedProduct(item);
  };
 

  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          // Delete the item from the server
          await axios.put(
            `login-service-mongo/api/loginservice/v2/delete`,
            item
          );
         
          // Update the state by filtering out the deleted item
          setTableDatas((prevData) => {
            const newRows = prevData.rows.filter((row) => row.id !== item.id);
            return { ...prevData, rows: newRows };
          });
 
          swal("Your User has been deleted!", {
            icon: "success",
          });
        } catch (error) {
          console.error("Error deleting item:", error);
          swal("Error deleting record!", {
            icon: "error",
          });
        }
      } else {
        swal("Your record is safe!");
      }
    });
  }
 
 

  function update(object) {
    axios
      .put(`login-service-mongo/api/loginservice/v2/update`, object)
      .then((response) => {
        setIsactive(response?.data?.data?.dto?.isactive);
      
 
        swal("", "Status updated successfully.", "success");
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
 
  const onChangePassword = (item) => {
 
    setPassdata(item);
    setopenpopupPassword(true);
  };
 
  const toggler = (checked, request) => {
    delete request.switch;
   
 
    if (checked) {
      request.recstatus = "APPROVED";
    } else {
        request.recstatus = "PENDING";
    }
    update(request);
  };
 
  const getData = (code) => {
    setLoading(true);
    axios
 
      .get(`login-service-mongo/api/loginservice/v2/get?rolecode=${code}`)
     
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          if (item.recstatus == "APPROVED") {
            // setChecked(true);
            item.switch = (
              <FormControlLabel
                name={item.id}
                control={
                  <Switch
                    onClick={(e) => toggler(e.target.checked, item)}
                    defaultChecked
                  />
                }
                label=""
              />
            );
          }
          if (item.recstatus == "PENDING") {
            // setChecked(true);
            item.switch = (
              <FormControlLabel
                name={item.id}
                control={
                  <Switch onClick={(e) => toggler(e.target.checked, item)} />
                }
                label=""
              />
            );
          }
          item.action = (
            <span>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          item.view = (
            <span>
               <Link to={{}}>
                      <PreviewIcon
                        fontSize="medium"
                        onClick={() => {
                          viewproductDetail(item);
                        }}
                      />
                    </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        setTableDatas(dataObj);
 
        // setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  useEffect(() => {

    getData('DE001');
  }, [isactive]);

  return (
    <DashboardLayout>
      <Card>
        <MDTypography
          variant="h5"
          fontWeight="medium"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
        Dealar Approval List
        </MDTypography>
 
      
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />
        {!loading && (
          <MDBox pt={3}>
            <MDBox>
              <Card>
                <DataTable table={tableDatas} canSearch={true} />
              </Card>
            </MDBox>
          </MDBox>
        )}
      </Card>
      {/* <Footer /> */}
      {openpopup && (
  <ViewDelar
  openpopup={openpopup}
  setopenpopup={setopenpopup}
  title={"View Detail"}
  selectedProduct={selectedProduct}
/>

      )}
    
    </DashboardLayout>
  );
};
 

export default DelarMaster;
